import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useLocalStorage } from '../../hooks'
import { useStores } from '../../stores/RootStore'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CUSTOMER } from '../../scenes/Billing/commonQueries'
import { ORGANIZATION_UPDATE } from '../../scenes/OrganizationManager/commonQueries'

const PLAN_REDIRECT_KEY = 'sd-plan-redirect'

export const RedirectToCheckout = ({ orgData }) => {
  const [storedValue, , clearStorageValue] = useLocalStorage(PLAN_REDIRECT_KEY)
  const history = useHistory()
  const { stores: { auth } } = useStores()
  const { user: { organizationId, primaryEmail } } = auth
  const { checkoutLink } = orgData || {}
  const linkToRedirect = checkoutLink || storedValue
  const isSureDoneUser = primaryEmail?.endsWith('@suredone.com')

  const { data: stripeCustomerData } = useQuery(GET_CUSTOMER)
  const { GetCustomer } = stripeCustomerData || {}
  const { subscription } = GetCustomer || {}
  const { status } = subscription || {}

  const [updateOrganization] = useMutation(ORGANIZATION_UPDATE)

  useEffect(() => {
    if (storedValue && !isSureDoneUser) { // Prevents storing the checkoutLink for SureDone users
      updateOrganization({ variables: { id: organizationId, input: { checkoutLink: `${storedValue}` } } })
      clearStorageValue(PLAN_REDIRECT_KEY)
    }
    if (status && status !== 'active' && linkToRedirect) {
      history.push(`/settings/billing/custom-checkout/${linkToRedirect}`)
    }
    // eslint-disable-next-line
  }, [status])

  return null
}

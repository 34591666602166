import React, { useState } from 'react'
import Select from 'react-select'
import { ActionButton, Pill } from '../../components'
import { Input, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'

export const FilterModal = ({ modal, toggle, actualFilterAttempt, handleAddFilter }) => {
  const { value: key, label, type, filters } = actualFilterAttempt || {}
  const [selectValue, setSelectValue] = useState('=')
  const [inputValue, setInputValue] = useState('')

  const options = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equals' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater or equal to' },
    { value: '<', label: 'lower than' },
    { value: '<=', label: 'lower or equal to' }
  ]

  const handleChange = event => {
    setSelectValue(event?.value)
  }

  // Apply filter when click on a fixed option
  const applyFilter = event => {
    toggle()
    handleAddFilter(event)
  }

  // Submit when is a form with an input
  const handleSubmit = e => {
    toggle()
    e.preventDefault()
    handleAddFilter({ key, opr: selectValue, val: inputValue?.trim() })
  }

  return (
    <Modal isOpen={modal} toggle={toggle} onOpened={() => setInputValue('')} centered autoFocus={false}>
      <ModalHeader toggle={toggle}>
        <span style={{ fontWeight: '500', fontSize: '1.15rem' }}>
          Filter by <b style={{ fontWeight: '700' }}>{label}</b>{type === 'fixed' && ', equal to'}
        </span>
      </ModalHeader>
      <ModalBody>
        {type === 'input'
          ? (
            <form onSubmit={e => handleSubmit(e)}>
              <Row className='d-flex justify-content-between align-items-center'>
                <Col xs='auto'>
                  <p className='m-0'>{label}</p>
                </Col>
                <Col xs={5}>
                  <Select
                    autoFocus
                    options={options}
                    clearable={false}
                    value={selectValue}
                    placeholder='equals'
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Input
                    autoFocus
                    className='w-100'
                    placeholder='123'
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className='my-3 justify-content-end'>
                <Col xs={12} sm='auto'>
                  <ActionButton
                    type='submit'
                    color='primary'
                    className='w-100'
                    onClick={e => handleSubmit(e)}
                    disabled={!selectValue || !inputValue}
                  >
                    Add filter
                  </ActionButton>
                </Col>
              </Row>
            </form>)
          : (
            <Row>
              <Col className='d-flex flex-wrap mb-3'>
                {filters?.map((f, idx) =>
                  <Pill
                    key={idx}
                    size='lg'
                    rounded
                    color='primary'
                    className='mr-2 mb-2'
                    onClick={() => applyFilter({ key, opr: '=', val: f })}
                  >
                    {f}
                  </Pill>
                )}
              </Col>
            </Row>)}
      </ModalBody>
    </Modal>
  )
}

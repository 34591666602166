import React, { useState } from 'react'
import { ErrorModal } from './ErrorModal'
import { FaSync as Refresh } from 'react-icons/fa'
import { FiCheckCircle as Check } from 'react-icons/fi'
import { ActionButton } from './ActionButton'
import { useStores } from '../stores/RootStore'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const RESEND_INVITE = gql`
  mutation resendUserInvite($userId: ID!) {
    resendInvite(userId: $userId)
  }
`

export const ResendEmailInvite = ({ user }) => {
  // userId is the id from who will receive again the invite
  const { id: userId, hasPendingInvite } = user || {}
  const { stores: { auth } } = useStores()
  const { user: { organizationId } } = auth

  const [successSent, setSuccessSent] = useState(false)
  const [resendInvite, { loading, error }] = useMutation(
    RESEND_INVITE,
    { onCompleted: () => setSuccessSent(true) }
  )

  return hasPendingInvite && auth.permissions.hasScopeInOrganization('organization:users:write', organizationId)
    ? (
      <ActionButton
        className='mr-1'
        color='secondary'
        loading={loading}
        disabled={successSent}
        icon={successSent ? <Check /> : <Refresh size='12' />}
        onClick={() => resendInvite({ variables: { userId } })}
      >
        {successSent ? 'Sent invite' : 'Resend invite'}
        {error && <ErrorModal header='Error sending invitation' error={error?.message} />}
      </ActionButton>)
    : null
}

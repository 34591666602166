import { useStores } from '../../../stores/RootStore'

export const daysToSelect = [
  { value: 1, label: 'Today' },
  { value: 2, label: 'Yesterday' },
  { value: 3, label: '3 days ago' },
  { value: 4, label: '4 days ago' },
  { value: 5, label: '5 days ago' },
  { value: 6, label: '6 days ago' },
  { value: 7, label: '7 days ago' }
]

const handleMessage = m => {
  if (!m) return 'Missing error'

  if (typeof m === 'object') {
    if (m.errors) {
      if (Array.isArray(m?.errors)) {
        return m.errors?.[0]?.title || m.errors?.[0] || ''
      } else {
        return m.errors?.ingestionError?.description || ''
      }
    }

    return m.message || m.error || ''
  }

  return m
}

const handleLogErrors = (logErrors) => {
  if (!logErrors) return ''

  if (Array.isArray(logErrors)) {
    if (logErrors.length === 1) {
      return {
        content: logErrors[0],
        count: logErrors.length
      }
    } else {
      return {
        content: logErrors,
        count: logErrors.length
      }
    }
  }
}

export const prepareStatuses = (items) => {
  const appEndpoint = window.serverConfig.legacyAppEndpoint
  const { stores: { auth } } = useStores()
  const { user: { organizationId } } = auth || {}

  if (!items?.length) return []

  const data = items.map(i => {
    const { id, _id, lastError, lastLog, lastStatusFullLog, lastErrorFullLog } = i || {}
    const { identifier } = _id || {}
    const { _id: logId } = lastLog || {}
    const { fieldData } = lastStatusFullLog || {}
    const { media1: media } = fieldData || {}

    const result = lastError?.result || lastLog?.result
    const product = `${appEndpoint}/#!/products/edit/${identifier}/guid`
    const title = lastErrorFullLog?.fieldData?.title || lastStatusFullLog?.fieldData?.title || ''
    const warnings = lastErrorFullLog?.warnings || lastStatusFullLog?.warnings || ''
    const message = warnings || handleMessage(lastErrorFullLog?.message || lastStatusFullLog?.message || '')
    const errors = handleLogErrors(lastErrorFullLog?.errors || lastStatusFullLog?.errors)
    const requestId = lastErrorFullLog?.requestId || lastStatusFullLog?.requestId || ''
    const columnsToShow = encodeURIComponent('identifier,result,action,context,createdAt,type,method,operation,request,response')
    const relatedLogSearch = encodeURIComponent(`filters={"filterType":"AND","filters":[{"key":"requestId","opr":"=","val":"${requestId}"}],"page":0,"size":10}&columns=${columnsToShow}`)
    const log = `${appEndpoint}/logs/${organizationId}/${logId}?${relatedLogSearch}`

    return { id, result, product, media, identifier, title, message, log, errors }
  })

  return data
}

/**
 * Adds an extra filter to show just error or warning results
 * @param {'all'|'error'|'warning'} type
 * @param {[log]} items
 * @returns {[log]}
*/
export const filterStatuses = (type, items) => {
  if (!type || type === 'all') {
    return items
  } else {
    return items?.filter(i => i?.result === type)
  }
}

export const prepareForDownload = (items, type) => {
  if (!items?.length) return []

  if (type === 'csv') {
    // headers has the attributes that will be present in the file
    const headers = ['identifier', 'result', 'message', 'errors', 'title', 'product', 'log']
    const rows = items?.map(i => headers?.map(e => {
      const value = i[e]
      if (e === 'errors' && value?.count) {
        if (typeof value?.content === 'string') {
          // eslint-disable-next-line
          return value.content?.replaceAll('\"', '')
        } else {
          return value.content.join('  –––  ')
        }
      } else {
        return value
      }
    }))
    return [headers, ...rows]
  }

  if (type === 'json') {
    // id, media and everything that is not part of {...rest} will be removed from the file
    const filteredItems = items?.map(({ id, media, ...rest }) => rest)
    return JSON.stringify(filteredItems || {}, null, '\t')
  }
}
